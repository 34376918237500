export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING";
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS";
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED";

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE";

export const baseApi = "https://production-kingdom-store-phase3-server-bp06.onrender.com";

export const baseApi2 = "https://production-kingdom-store-phase3-server-bp06.onrender.com";
// export const baseApi2 = "http://localhost:3000";

export const imgDefaultUrl = `./assets/images/default/df-img.png`;
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`;
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`;
export const img404notfound = `./assets/images/notfound/404notfound.jpg`;

export const paginate = 10000; // limit 10 k
export const sortASC = "ASC";
export const sortDESC = "DESC";

export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";

export const server = {
  // ------------------------ REVIEW -----------------------------------------------------
  GET_PAGE_REVIEWS_URL: `/api/buyPage/reviews`,
  INSERT_CONTACT_URL: `/api/contactPage`,

  // ------------------------ ERP ------------------------------------------------------------
  GET_ERP_SIGNIN_ID_URL: `/api/auth/signIn`,
  GET_ERP_SIGNUP_ID_URL: `/api/auth/signup`,
  GET_ERP_SIGNOUT_ID_URL: `/api/auth/signout`,
  GET_ERP_LIST_URL: ``,

  // ------------------------ REVIEWS --------------------------------------------------------
  GET_REVIEWS_URL: `/api/reviews`,
  GET_REVIEW_BY_ID_URL: `/api/reviews`,

  INSERT_REVIEWS_URL: `/api/reviews`,

  UPDATE_REVIEW_URL: `/api/reviews`,

  DELETE_REVIEW_URL: `/api/reviews`,

  // ------------------------ CONTACTS --------------------------------------------------------
  GET_CONTACTS_URL: `/api/contacts`,

  UPDATE_CONTACTS_URL: `/api/contacts`,

  // ------------------------ ESTIMATE PRICE --------------------------------------------------
  GET_MOBILE_BRAND_URL: `/api/v1/mobile/brand`,
  GET_MOBILE_MODEL_URL: `/api/v1/mobile/model`,
  GET_MOBILE_CAPACITY_URL: `/api/v1/mobile/capacity`,

  GET_BANNER_URL: `/api/v1/banner`,
  GET_REVIEW_URL: `/api/v1/review`,
  GET_SALE_STEP_CONTENT_URL: `/api/v1/sale-step-content`,
  GET_SALE_BY_KINGDOM_URL: `/api/v1/sale-by-kingdom`,

  // /api/v1/review?page=1&perPage=2
  // /api/v1/sale-step-content
  // /api/v1/sale-by-kingdom

  // ------------------------  SUMMARY RESULT -------------------------------------------------
  GET_PROVINCE_URL: `/api/v1/province`,
  GET_DISTRICT_URL: `/api/v1/district`,
  GET_TRAIN_LINE_URL: `/api/v1/train`,
  GET_TRAIN_STATION_URL: `/api/v1/train/station`,
  INSERT_SALE_INFO_URL: `/api/v1/sale-info`,

  // ------------------------ EVALUATE INTEGRITY ----------------------------------------------
  GET_ISSUE_URL: `/api/v1/issue`,
  GET_SALE_INFO_CALCULATE_URL: `/api/v1/sale-info/calculate`,

  // ------------------------ UPLOADS ---------------------------------------------------------
  UPLOAD_FILE: `/api/upload`,
  UPLOAD_FILES: `/api/upload/multiple`,
};
